/* eslint max-lines: "off" */
import lodash, {isObject, toNumber} from "lodash";
import Vue from "vue";
import apolloClient from "../../ApolloClient";
import {convertTemplate} from "@/service/convertTemplate.services";
import { extractFees } from "../../service/convertTemplate.services";
import gql from "graphql-tag";
import store from "../";

const defaultState = {
    "values": {},
    "shareholders": [],
    "documentsPerson": [],
    "fees": [],
    "tasks": [],
    "cards": [],
    "paymentMechanisms": [],
    "companyNumber": "",
    "dnbSearchParams": {},
    "dnbMatches": [],
    "dnbResponseMessage": "",
    "custom_rebate_proposal": {},
    "custom_rebate_setup_instructions": {},
    "dialogSolaris": false,
    "solarisSearchParams": {},
    "salesforceFields": []
};

const textAreaFields = [
    "comments",
    "customer_comments",
    "custom_rebate_offer_pre_text",
    "custom_rebate_proposal_text",
    "custom_rebate_offer_post_text",
    "custom_rebate_setup_instructions_text",
    "mdm_info"
];

// Counter is used in combination with tempId to create a uniqueId for new listItems.
let counter = 1;
// tempId is used to indicate a new entity that has been created in the frontend.
const tempId = "New";

/**
 * Takes an array of data, and return a string
 * @param {array} itemArray The array which contains the data to be passed along in the gql form mutation
 * @param {string} gqlTypeName The name of the graphql type to be used in the mutation
 * @returns {string} mutationQuery Return the query for the graphql type
 */
function convertQuery (itemArray, gqlTypeName) {
    let mutationQuery = "";
    mutationQuery += `${gqlTypeName}: [`;
    itemArray.forEach((item) => {
        mutationQuery += "{";
        for (const key of Object.keys(item)) {
        // eslint-disable-next-line no-extra-parens
            if (key !== "id" || (key === "id" && typeof item[key] !== "string")) {
                let fieldValue = item[key];
                if (typeof fieldValue === "string") {
                    fieldValue = fieldValue
                        .replace(/\\/gu, "\\\\")
                        .replace(/"/gu, "\\\"")
                        .replace(/\n/gu, "\\n")
                        .replace(/\r/gu, "\\r");
                }
                mutationQuery += typeof fieldValue === "string" ? `${key}: "${fieldValue}" ` : `${key}: ${fieldValue} `;
                mutationQuery += "\r\n";
            }
        }
        mutationQuery += "}, ";
        return true;
    });
    mutationQuery += "]";
    return mutationQuery;
}

function cleanArray (array) {
    return array && array.length > 0 ? array.filter((item) => !(
        item.deleted &&
        typeof item.id === "string" &&
        item?.id?.startsWith(tempId))) : [];
}

function excludeFields (obj, fieldsToExclude) {
    const cleanedObj = { ...obj };
    fieldsToExclude.forEach((field) => delete cleanedObj[field]);
    return cleanedObj;
}

const excludedRoleFields = ["roles", "isSignee", "isLegalRepresentative", "isUbo", "isContactPerson", "state"];
const excludedIndividualFields =
    [
        "roles",
        "address_city",
        "address_line_1",
        "address_line_2",
        "address_postal_code",
        "address_state",
        "address_country",
        "birth_date",
        "country_id",
        "email",
        "first_name",
        "form_id",
        "last_name",
        "nationality",
        "phone_number",
        "phone_number_country_code",
        "signature_location",
        "job_function",
        "share_capital",
        "marketing_agree",
        "state"
    ];

/**
 * Turns relational objects into string for gql query
 * @returns {string} Return the query for graphql
 * @param state
 */
function createRelationMutationQuery (state) {
    state.individuals?.forEach((individual) => {
        // eslint-disable-next-line camelcase,no-unused-expressions
        individual.marketing_agree ? individual.marketing_agree = Number(individual.marketing_agree) : null;
        // eslint-disable-next-line camelcase,no-unused-expressions
        individual.share_capital = individual.share_capital ? toNumber(individual.share_capital) : 0.0;
    });

    let relationMutationQuery = "";
    const relationalData = {
        "feeData": cleanArray(state.fees),
        "formAddressData": state.formAddresses,
        "shareholderData": cleanArray(state.shareholders),
        "solarisBankPersonData": state.documentsPerson,
        "cardData": cleanArray(state.cards),
        "individualData": cleanArray(state.individuals?.map((individual) => excludeFields(individual, excludedRoleFields))),
        "roleData": cleanArray(state.individuals?.map((individual) => excludeFields(individual, excludedIndividualFields))),
        "paymentMechanismData": cleanArray(state.paymentMechanisms),
        "taskData": state.tasks,
        "fleetFieldData": state.fleetField,
        "salesforceFields": state.salesforceFields
    };
    for (const [gqlTypeName, itemArray] of Object.entries(relationalData)) {
        if (!lodash.isEmpty(itemArray)) {
            relationMutationQuery += convertQuery(
                itemArray,
                gqlTypeName
            );
        }
    }
    return relationMutationQuery;
}

/**
 * Turns document objects into string for gql query
 * @returns {{documentMutationQuery: string, documentMutationVariables: string}} Return the query for graphql
 * @param {array} documentData documents
 */
function createDocumentMutationQuery (documentData) {
    let documentMutationQuery = "(";
    let documentMutationVariables = "";
    for (const document of Object.keys(documentData)) {
        if (documentData[document]) {
            const documentType = document === "documents" ? "[SolarisbankDocument]!" : "Upload!";
            documentMutationQuery += `$${document}: ${documentType} `;
            documentMutationVariables += `${document}: $${document} `;
        }
    }
    if (documentMutationQuery === "(") {
        return {
            "documentMutationQuery": " ",
            "documentMutationVariables": " "
        };
    }

    documentMutationQuery += ")";
    return {
        documentMutationQuery,
        documentMutationVariables
    };
}

/**
 * Turns the form object into a string that can be used in gql queries
 * @returns {string|string} Return the query for graphql
 * @param state
 */
function createFormMutationQuery (state) {
    let formMutationQuery = "";
    const formData = state.values;
    delete formData.oaf_unique_id;

    for (const key of Object.keys(formData)) {
        let fieldValue = formData[key];
        if (typeof fieldValue === "string" && !textAreaFields.includes(key)) {
            fieldValue = fieldValue
                .replace(/\\/gu, "\\\\")
                .replace(/"/gu, "\\\"")
                .replace(/\n/gu, "\\n")
                .replace(/\r/gu, "\\r");
        }
        if (textAreaFields.includes(key) && typeof fieldValue === "string") {
            fieldValue = Buffer.from(fieldValue).toString("base64");
        }

        let parameter = key;
        // If translation available, translate key to be sent with mutation as correct parameter
        const translation = store.getters["formSegments/getTranslationFromKey"](key);
        if (translation) {
            parameter = translation;
        }
        formMutationQuery += typeof fieldValue === "string" ? `${parameter}: "${fieldValue}",  ` : `${parameter}: ${fieldValue} `;
    }
    return formMutationQuery;
}

/**
 * Set the state of a form that is equal to the status.
 * @param state
 * @returns {string}
 */
function setFormState (state, rootGetters) {
    return rootGetters["statusses/getStatusses"].find((element) => element.status === state.values.status).key;
}

function deleteFields (values) {
    delete values.county;
    delete values.region;
    delete values.fraud_check_report;
    delete values.solaris_state;

    if (values.coc_info === null || isObject(values.coc_info)) {
        delete values.coc_info;
    }

    return values;
}

function getSalesforcefields (state, rootGetters) {
    const salesforceFields = rootGetters["salesforceFields/getSalesforceFields"];
    if (state.values.id) {
        // eslint-disable-next-line camelcase
        salesforceFields.form_id = state.values.id;
    }
    return salesforceFields;
}

function deleteItem (itemArray, deleteId) {
    return itemArray.map((item) => {
        if (item.id === deleteId) {
            item.deleted = true;
        }
        return item;
    });
}
function deleteAllItems (itemArray) {
    return itemArray.map((item) => {
        item.deleted = true;
        return item;
    });
}

const mutations = {
    SET_FORM_VALUES (currentState, data) {
        Vue.set(currentState, "values", data);
    },
    SET_DNB_SEARCH_PARAMS (currentState, data) {
        Vue.set(currentState, "dnbSearchParams", data);
    },
    UPDATE_FORM_VALUE (currentState, data) {
        Vue.set(currentState.values, data.name, data.value);
    },
    SET_SITE_VALUES_FOR_FORM (currentState, data) {
        Vue.set(currentState, "siteValues", data);
    },
    // eslint-disable-next-line max-statements
    SET_NEW_FORM_VALUES (currentState, data) {
        if (!lodash.isEmpty(data)) {
            const formTypes = data.rootState.formTypes.formTypes;
            const templateData = data.template_values;
            if (typeof templateData !== "undefined" && formTypes !== null) {
                const values = {...currentState.values};
                const newData = convertTemplate(data.template_values, formTypes);

                // eslint-disable-next-line camelcase
                values.category_id = data.category;
                values.country = data.country;

                Object.keys(newData).forEach((key) => {
                    values[key] = newData[key];
                });
                Object.keys(values).forEach((field) => {
                    if (typeof values[field] === "boolean") {
                        values[field] = values[field] ? 1 : 0;
                    }
                });

                Vue.set(currentState, "values", values);
            }
        }
    },
    FETCH_PHONE_NUMBER_COUNTRY_CODES (currentState) {
        Vue.set(
            currentState.values,
            "contact_phone_number_country_code",
            currentState.values.contact_phone_number_country_code ?? currentState.values.phone_number_country_code
        );
        Vue.set(
            currentState.values,
            "mobile_number_country_code",
            currentState.values.mobile_number_country_code ?? currentState.values.phone_number_country_code
        );
    },
    SET_DEFAULT_VALUES (currentState, fields) {
        const formTypes = fields.rootState.formTypes.formTypes;
        const data = fields.data;
        if (!lodash.isEmpty(data) && formTypes !== null) {
            const convertedValues = convertTemplate(data, formTypes);
            Vue.set(currentState, "values", convertedValues);
        }
    },
    CHANGE_DEFAULT_VALUES (currentState, fields) {
        const formTypes = fields.rootState.formTypes.formTypes;
        const data = fields.data;
        if (!lodash.isEmpty(data) && formTypes !== null) {
            const convertedValues = convertTemplate(data, formTypes);
            const combinedValues = {...currentState.values, ...convertedValues};
            Vue.set(currentState, "values", combinedValues);
        }
    },
    CHANGE_TEMPLATE_VALUES (currentState, data) {
        const templateData = data.template_values;
        const formTypes = data.rootState.formTypes.formTypes;
        const newData = convertTemplate(templateData, formTypes);
        newData.country = data.country;
        // eslint-disable-next-line camelcase
        newData.category_id = data.category;
        const updatedValues = {...currentState.values, ...newData};
        currentState.values = {...currentState.values, ...updatedValues};
    },
    UPDATE_DNB_SEARCH_PARAMS (currentState, data) {
        Vue.set(currentState.dnbSearchParams, data.name, data.value);
    },
    SET_SHAREHOLDERS (currentState, shareholders) {
        Vue.set(currentState, "shareholders", shareholders);
    },
    ADD_SHAREHOLDERS (currentState) {
        const newShareholder = {
            "id": `${tempId}${counter}`,
            "form_id": currentState.values.id,
            "name": "",
            "birthday": null,
            "address": "",
            "zipcode": "",
            "city": ""
        };
        if (!newShareholder.form_id) {
            delete newShareholder.form_id;
        }
        currentState.shareholders.push(newShareholder);
        counter += 1;
    },
    UPDATE_SHAREHOLDERS (currentState, data) {
        const index = currentState.shareholders.findIndex((shareholder) => shareholder.id === data.id);
        const currentShareHolderToUpdate = currentState.shareholders[index];
        const updatedShareholder = { ...currentShareHolderToUpdate,
            ...data};

        currentState.shareholders = Object.assign(
            [],
            currentState.shareholders,
            {[index]: updatedShareholder}
        );
    },
    DELETE_SHAREHOLDERS (currentState, shareholderId) {
        const filteredShareholders = deleteItem(currentState.shareholders, shareholderId);
        Vue.set(currentState, "shareholders", filteredShareholders);
    },
    SET_DOCUMENTS_PERSON (currentState, documentsPerson) {
        Vue.set(currentState, "documentsPerson", documentsPerson);
    },
    SET_FORM_ADDRESSES (currentState, formAddresses) {
        Vue.set(currentState, "formAddresses", formAddresses);
    },
    ADD_EMPTY_FORM_ADDRESS (currentState) {
        currentState.formAddresses.push({
            "id": `NewFormAddress${counter}`,
            "form_id": currentState.values.id,
            "address_type": "",
            "address": "",
            "zipcode": "",
            "city": "",
            "country": ""
        });
        counter += 1;
    },
    UPDATE_FORM_ADDRESS (currentState, data) {
        const index = currentState.formAddresses.findIndex((formAddress) => formAddress.id === data.id);
        const currentFormAddressToUpdate = currentState.formAddresses[index];
        const updatedFormAddress = {
            ...currentFormAddressToUpdate,
            ...data
        };

        currentState.formAddresses = Object.assign(
            [],
            currentState.formAddresses,
            {[index]: updatedFormAddress}
        );
    },
    DELETE_FORM_ADDRESS (currentState, id) {
        const filteredFormAddresses = currentState.formAddresses.filter((formAddresses) => formAddresses.id !== id);
        Vue.set(currentState, "formAddresses", filteredFormAddresses);
    },
    UPDATE_DOCUMENTS_PERSON (currentState, data) {
        const index = currentState.documentsPerson.findIndex((documentPerson) => documentPerson.id === data.id);
        const currentDocumentPersonUpdate = currentState.documentsPerson[index];
        const updatedDocumentPerson = { ...currentDocumentPersonUpdate,
            ...data};

        currentState.documentsPerson = Object.assign(
            [],
            currentState.documentsPerson,
            {[index]: updatedDocumentPerson}
        );
    },
    ADD_DOCUMENT_PERSON (currentState, data) {
        data.id = `${tempId}${counter}`;
        currentState.documentsPerson.push(data);
        counter += 1;
    },

    UPDATE_INDIVIDUAL (currentState, data) {
        const index = currentState.individuals.findIndex((individual) => individual.id === data.id);
        const currentIndividualUpdate = currentState.individuals[index];
        const updatedIndividual = { ...currentIndividualUpdate,
            ...data};

        currentState.individuals = Object.assign(
            [],
            currentState.individuals,
            {[index]: updatedIndividual}
        );
    },
    ADD_INDIVIDUAL (currentState, data) {
        data.id = `${tempId}${counter}`;
        currentState.individuals.push(data);
        counter += 1;
    },
    SET_FEES (currentState, fees) {
        Vue.set(currentState, "fees", fees || []);
    },
    ADD_EMPTY_FEES (currentState) {
        const newFee = {
            "id": `${tempId}${counter}`,
            "form_id": currentState.values.id,
            "category": "",
            "amount": 0,
            "frequency": "",
            "fee_option": null,
            "start_date": null,
            "end_date": null,
            "type": 0
        };
        if (!newFee.form_id) {
            delete newFee.form_id;
        }
        currentState.fees.push(newFee);
        counter += 1;
    },
    ADD_FEES (currentState, data) {
        data.id = `${tempId}${counter++}`;
        if (data.amount) {
            data.amount = parseFloat(data.amount);
        }
        currentState.fees.push(data);
    },
    UPDATE_FEES (currentState, data) {
        const index = currentState.fees.findIndex((fee) => fee.id === data.id);
        const currentFeeToUpdate = currentState.fees[index];
        const updatedFee = { ...currentFeeToUpdate,
            ...data};

        currentState.fees = Object.assign(
            [],
            currentState.fees,
            {[index]: updatedFee}
        );
    },
    EDIT_FEES (currentState, data) {
        for (const [key, value] of Object.entries(data)) {
            const currentFeeToUpdate = currentState.fees[key];
            const updatedFee = { ...currentFeeToUpdate,
                ...value};

            currentState.fees = Object.assign(
                [],
                currentState.fees,
                {[key]: updatedFee}
            );
        }
    },
    DELETE_FEES (currentState) {
        const filteredFees = deleteAllItems(currentState.fees);
        Vue.set(currentState, "fees", filteredFees);
    },
    DELETE_FEE (currentState, feeId) {
        const filteredFees = deleteItem(currentState.fees, feeId);
        Vue.set(currentState, "fees", filteredFees);
    },
    SET_TASKS (currentState, tasks) {
        Vue.set(currentState, "tasks", tasks);
    },
    UPDATE_TASKS (currentState, tasks) {
        const index = currentState.tasks.findIndex((task) => task.id === tasks.id);
        const currentTaskToUpdate = currentState.tasks[index];
        const updatedTask = { ...currentTaskToUpdate, ...tasks};
        currentState.tasks = Object.assign([], currentState.tasks, {[index]: updatedTask});
    },
    SET_CARDS (currentState, cards) {
        Vue.set(currentState, "cards", cards);
    },
    SET_INDIVIDUALS (currentState, individuals) {
        Vue.set(currentState, "individuals", individuals);
    },
    SET_INDIVIDUALS_ROLES (currentState, individualRoles) {
        Vue.set(currentState, "individual_roles", individualRoles);
    },
    SET_ROLES (currentState, roles) {
        Vue.set(currentState, "roles", roles);
    },
    ADD_CARDS (currentState) {
        const newCard = {
            "id": `${tempId}${counter}`,
            "form_id": currentState.values.id,
            "driver_name": "",
            "purchase_option": "",
            "international": "",
            "card_text": "",
            "card_comment1": "",
            "card_comment2": "",
            "license_plate": "",
            "use_sum_kilometer": "",
            "cost_centre": null,
            "pin_type": ""
        };
        if (!newCard.form_id) {
            delete newCard.form_id;
        }
        currentState.cards.push(newCard);
        counter += 1;
    },
    UPDATE_CARDS (currentState, data) {
        const index = currentState.cards.findIndex((card) => card.id === data.id);
        const currentCardToUpdate = currentState.cards[index];
        const updatedCard = { ...currentCardToUpdate,
            ...data};

        currentState.cards = Object.assign(
            [],
            currentState.cards,
            {[index]: updatedCard}
        );
    },
    DELETE_CARDS (currentState, cardId) {
        const filteredCards = deleteItem(currentState.cards, cardId);
        Vue.set(currentState, "cards", filteredCards);
    },
    UPDATE_BIRTH_DATE (currentState, { id, value }) {
        const individualIndex = currentState.individuals.findIndex((individual) => individual.id === id);

        if (individualIndex !== -1) {
            // eslint-disable-next-line camelcase
            currentState.individuals[individualIndex].birth_date = value;
        }
    },
    ADD_INDIVIDUALS (currentState) {
        const newIndividual = {
            "id": `${tempId}${counter}`,
            "form_id": currentState.values.id,
            "address_line_1": "",
            "address_line_2": "",
            "address_city": "",
            "address_postal_code": "",
            "address_state": "",
            "birth_date": null,
            "country_id": 1,
            "email": "",
            "first_name": "",
            "last_name": "",
            "nationality": "",
            "phone_number": "",
            "phone_number_country_code": "",
            "share_capital": 0,
            "marketing_agree": null
        };
        if (!newIndividual.form_id) {
            delete newIndividual.form_id;
        }
        currentState.individuals.push(newIndividual);
        counter += 1;
    },
    UPDATE_INDIVIDUALS (currentState, data) {
        const index = currentState.individuals.findIndex((individual) => individual.id === data.id);
        const currentIndividualToUpdate = currentState.individuals[index];
        const updatedIndividual = { ...currentIndividualToUpdate,
            ...data};

        currentState.individuals = Object.assign(
            [],
            currentState.individuals,
            {[index]: updatedIndividual}
        );
    },
    DELETE_INDIVIDUALS (currentState, individualId) {
        const filteredIndividuals = deleteItem(currentState.individuals, individualId);
        Vue.set(currentState, "individuals", filteredIndividuals);
    },
    SET_COC_INFO (currentState, cocInfo) {
        Vue.set(currentState.values, "coc_info", cocInfo);
    },
    SET_COC_NUMBER (currentState, cocNumber) {
        Vue.set(currentState.values, "coc_nr", cocNumber);
    },
    SET_PAYMENT_MECHANISMS (currentState, paymentMechanisms) {
        Vue.set(currentState, "paymentMechanisms", paymentMechanisms);
    },
    ADD_PAYMENT_MECHANISMS (currentState) {
        const newPaymentMechanism = {
            "id": `${tempId}${counter}`,
            "form_id": currentState.values.id,
            "card_holder": "",
            "vehicle_registration_number": "",
            "additional_details": "",
            "additional_data_prompts": "",
            "service_code": ""
        };

        if (!currentState.values.id) {
            delete newPaymentMechanism.form_id;
        }
        currentState.paymentMechanisms.push(newPaymentMechanism);
        counter += 1;
    },
    UPDATE_PAYMENT_MECHANISMS (currentState, paymentMechanismData) {
        const paymentMechanismIdx = currentState.paymentMechanisms.findIndex((pM) => pM.id === paymentMechanismData.id);
        const oldPaymentMechanismData = currentState.paymentMechanisms[paymentMechanismIdx];
        const updatedPaymentMechanism = { ...oldPaymentMechanismData, ...paymentMechanismData };

        currentState.paymentMechanisms = Object.assign(
            [],
            currentState.paymentMechanisms,
            {[paymentMechanismIdx]: updatedPaymentMechanism}
        );
    },
    DELETE_PAYMENT_MECHANISMS (currentState, paymentMechanismId) {
        const filteredPaymentMechanisms = deleteItem(currentState.paymentMechanisms, paymentMechanismId);
        Vue.set(currentState, "paymentMechanisms", filteredPaymentMechanisms);
    },
    SET_DNB_MATCHES (currentState, dnbMatches) {
        Vue.set(currentState, "dnbMatches", dnbMatches);
    },
    SET_DNB_RESPONSE_MESSAGE (currentState, message) {
        Vue.set(currentState, "dnbResponseMessage", message);
    },
    SAVE_DNB_MATCH_TO_FORM (currentState, dnbMatch) {
        currentState.values = {
            ...currentState.values,
            "dnb_company_name": dnbMatch.dnbMatch.dnbCompanyName,
            "dnb_trading_name": dnbMatch.dnbMatch.dnbTradingName,
            "dnb_duns_number": dnbMatch.dnbMatch.dnbDunsNumber,
            "dnb_address": dnbMatch.dnbMatch.dnbAddress,
            "dnb_postal_code": dnbMatch.dnbMatch.dnbPostalCode,
            "dnb_city": dnbMatch.dnbMatch.dnbCity,
            "dnb_phone": dnbMatch.dnbMatch.dnbPhone,
            "dnb_out_of_business": dnbMatch.dnbMatch.dnbOutOfBusiness,
            "dnb_relevant": dnbMatch.dnbMatch.dnbRelevant,
            "dnb_location_status": dnbMatch.dnbMatch.dnbLocationStatus
        };
    },
    SET_AUTO_DNB_LOOKUP_RESULT (currentState, dnbResult) {
        Vue.set(currentState, "dnb_auto_look_up_result", dnbResult.dnbResult);
    },
    "SET_FORM_DOCUMENTS" (currentState, data) {
        Vue.set(currentState, data.type, data);
    },
    "CLEAR_OLD_VALUES" (currentState) {
        Vue.set(currentState, "values", {});
        Vue.set(currentState, "custom_rebate_proposal", {});
        Vue.set(currentState, "custom_rebate_setup_instructions", {});
    },
    SET_COMPANY_DETAILS (currentState, data) {
        // eslint-disable-next-line camelcase
        currentState.values.company_name = data.name;
        currentState.values.zipcode = data.address.postal_code;
        currentState.values.city = data.address.city;
        currentState.values.address = data.address.line_1;
        // eslint-disable-next-line camelcase
        currentState.values.legal_form = data.legal_form;
    },
    SET_SOLARIS_DIALOG (currentState, data) {
        if (data.params) {
            currentState.solarisSearchParams = data.params;
        }
        currentState.dialogSolaris = data.dialog;
    },
    SAVE_FLEET_AMOUNT (currentState, data) {
        currentState.values[data.sf_export_field] = data.value;
        Vue.set(currentState, "values", currentState.values);
    },
    SAVE_MDM_SEARCH (currentState, data) {
        // eslint-disable-next-line camelcase
        currentState.values.mdm_search_status = data.mdm_search_status;
        if (data.mdm_id && data.mdm_sold_to_number) {
            // eslint-disable-next-line camelcase
            currentState.values.mdm_id = String(data.mdm_id);
            // eslint-disable-next-line camelcase
            currentState.values.mdm_sold_to_number = data.mdm_sold_to_number;
        }
    },
    TRIM_FORM_FIELDS (currentState, data) {
        data.fieldsToTrim.forEach((field) => {
            if (data.fields[field]) {
                currentState.values[field] = data.fields[field];
            }
        });
    },
    UPDATE_SEPA_MANDATE_RECEIVED (currentState, data) {
        // eslint-disable-next-line camelcase
        currentState.values.sepa_mandate_received = data;
    },
    UPDATE_SEPA_CONTRACT_SIGNED (currentState, data) {
        const sepaTask = currentState.tasks.filter((task) => task.type === "ContractSigned")[0];
        if (sepaTask) {
            sepaTask.statusOverride = data;
        }
    }
};
function formatSiteValues (siteValues) {
    if (siteValues) {
        return `(${siteValues.code}) ${siteValues.name}`;
    }
    return null;
}
const getters = {
    "categoryId": (currentState) => () => currentState.values.category_id,
    "filteredCard": (currentState) => (cardId) => currentState.cards.find((item) => item.id === cardId),
    "filteredIndividual": (currentState) => (individualId) => currentState.individuals.find((item) => item.id === individualId),
    "document": (currentState) => (name) => currentState[name],
    "country": (currentState) => currentState.values.country,
    "fees": (currentState) => currentState.fees,
    "automatedDunResult": (currentState) => () => ({"name": currentState.dnb_auto_look_up_result}),
    "site": (currentState) => () => ({"name": formatSiteValues(currentState.siteValues)}),
    "fleet": (currentState) => [
        {
            "field_name": "Cars",
            "sf_export_field": "cars_amount",
            "value": currentState.values.cars_amount
        },
        {
            "field_name": "Trucks",
            "sf_export_field": "trucks_amount",
            "value": currentState.values.trucks_amount
        },
        {
            "field_name": "Vans",
            "sf_export_field": "vans_amount",
            "value": currentState.values.vans_amount
        },
        {
            "field_name": "Big trucks",
            "sf_export_field": "trucks_big_amount",
            "value": currentState.values.trucks_big_amount
        },
        {
            "field_name": "Busses",
            "sf_export_field": "busses_amount",
            "value": currentState.values.busses_amount
        },
        {
            "field_name": "Huge trucks",
            "sf_export_field": "trucks_huge_amount",
            "value": currentState.values.trucks_huge_amount
        }
    ],
    "status": (currentState) => currentState.values.status
};

const actions = {
    async setFormValues ({state, commit, rootState}, data) {
        // We don't have these fields in the database
        data.region = "";
        data.county = "";
        await commit("SET_FORM_VALUES", data);
        const {country, zipcode} = state.values;

        // setCountry and addressLookup are dependent on session countries
        if (rootState.countries.countries.length > 0) {
            await this.dispatch("zipcode/setCountry", country);
            await this.dispatch("zipcode/addressLookup", {country, zipcode});
        }
    },
    async setNewFormValues ({commit, rootState}, data) {
        data.rootState = rootState;
        await commit("SET_NEW_FORM_VALUES", data);
    },
    async setSiteValuesForForm ({commit}, data) {
        await commit("SET_SITE_VALUES_FOR_FORM", data);
    },
    async setDefaultValues ({commit, rootState}, data) {
        await commit(
            "SET_DEFAULT_VALUES",
            {rootState, data}
        );
    },
    async fetchPhoneNumberCountryCodes ({commit}) {
        await commit("FETCH_PHONE_NUMBER_COUNTRY_CODES");
    },
    // eslint-disable-next-line no-empty-pattern
    async changeDefaultValues ({commit, rootState}, data) {
        const query = gql`query {default_values_for_campaign (campaign_id:${data.campaignId})
                {defaultValues responseString}}`;
        await apolloClient.query({query}).then((responseData) => {
            const response = responseData.data.default_values_for_campaign;
            if (!response.responseString) {
                response.defaultValues.Form[0].properties.campaign = data.campaignId;
                const propertyValues = response.defaultValues.Form[0].properties;
                commit("CHANGE_DEFAULT_VALUES", {rootState, propertyValues});
                response.defaultValues.Fee.forEach((fee) => this.dispatch("applicationFormValues/addFees", fee.properties));
                if (response.defaultValues.FleetField) {
                    response.defaultValues.FleetField.forEach((fleetField) => this.dispatch("fleetField/addFleetfield", fleetField.properties));
                }
                if (response.defaultValues.SalesforceFormFields &&
                    response.defaultValues.SalesforceFormFields[0].properties) {
                    this.dispatch(
                        "salesforceFields/setSalesforceFields",
                        response.defaultValues.SalesforceFormFields[0].properties
                    );
                }
            }
        });
    },
    // eslint-disable-next-line complexity
    async setDnbSearchParams ({commit}, data) {
        const DnbSearchParameters = {
            "country_code": typeof data.formValues.data[0].country !== "undefined" ? data.formValues.data[0].country : "",
            "company_name": typeof data.formValues.data[0].company_name !== "undefined" ? data.formValues.data[0].company_name : "",
            "dunsNumber": typeof data.formValues.data[0].duns_number !== "undefined" ? data.formValues.data[0].duns_number : "",
            "territory": typeof data.formValues.data[0].province !== "undefined" ? data.formValues.data[0].province : "",
            "address": typeof data.formValues.data[0].address !== "undefined" ? data.formValues.data[0].address : "",
            "phoneNumber": typeof data.formValues.data[0].phonenumber !== "undefined" ? data.formValues.data[0].phonenumber : "",
            "zipcode": typeof data.formValues.data[0].zipcode !== "undefined" ? data.formValues.data[0].zipcode : "",
            "city": typeof data.formValues.data[0].city !== "undefined" ? data.formValues.data[0].city : "",
            "companyNumber": typeof data.formValues.data[0].vat_number !== "undefined" && data.formValues.data[0].vat_number
                ? data.formValues.data[0].vat_number
                : data.formValues.data[0].coc_nr,
            "maxResults": 10,
            "minimumConfidence": 5,
            "excludeOutOfBusiness": "Exclude Out of Business",
            "excludeUndeliverable": "",
            "excludeNonHeadquarters": "",
            "excludeUnreachable": "",
            "excludeNonMarketable": ""
        };
        await commit("SET_DNB_SEARCH_PARAMS", DnbSearchParameters);
    },
    async updateDnbSearchParams ({commit}, data) {
        await commit("UPDATE_DNB_SEARCH_PARAMS", data);
    },
    async updateFormValue ({commit, state}, data) {
        await commit("UPDATE_FORM_VALUE", data);

        const {country, zipcode} = state.values;
        if (data.name === "country") {
            this.dispatch("zipcode/setCountry", country);
        }

        if (["country", "zipcode"].includes(data.name)) {
            this.dispatch("zipcode/addressLookup", {zipcode, country});
        }
    },
    saveForm ({state, rootGetters}) {
        state.values = deleteFields(state.values);
        state.fleetField = rootGetters["fleetField/getFleetFields"];
        state.salesforceFields[0] = getSalesforcefields(state, rootGetters);
        state.values.state = setFormState(state, rootGetters);

        const relationMutationQuery = createRelationMutationQuery(state);
        const formMutationQuery = createFormMutationQuery(state);
        const proposal = state.custom_rebate_proposal;
        const instructions = state.custom_rebate_setup_instructions;
        const unfilteredDocuments = rootGetters["solarisDocuments/getDocuments"];
        const documents = unfilteredDocuments.filter((document) => document.business_id !== null);
        const variables = {
            "custom_rebate_proposal": !lodash.isEmpty(proposal) ? proposal : null,
            "custom_rebate_setup_instructions": !lodash.isEmpty(instructions) ? instructions : null,
            "documents": documents.length > 0 ? documents : null
        };
        const {documentMutationQuery, documentMutationVariables} = createDocumentMutationQuery(variables);
        // eslint-disable-next-line max-len
        const mutation = gql`mutation SaveForm ${documentMutationQuery} { saveform (${formMutationQuery} ${relationMutationQuery} ${documentMutationVariables} ) {  responseString errors reconfirmChanges insertedId } }`;
        return apolloClient.mutate({
            mutation,
            variables
        });
    },
    async trimFormFields ({commit, state}, fieldsToTrim) {
        const values = state.values;
        const data = {"fields": {}, fieldsToTrim};
        fieldsToTrim.forEach((field) => {
            data.fields[field] = typeof values[field] === "string" ? values[field].trim() : values[field];
        });
        await commit("TRIM_FORM_FIELDS", data);
    },
    deleteDocument ({state, dispatch}, type) {
        if (!["custom_rebate_proposal", "custom_rebate_setup_instructions"].includes(type)) {
            throw new Error("The file type you are deleting does not exist");
        }
        dispatch("setDocuments", {type, "content": null});
        const mutation = gql`mutation {delete_document(formId: ${state.values.id}, type: "${type}") {responseString}}`;
        return apolloClient.mutate({mutation});
    },
    async setShareholders ({commit}, shareholders) {
        await commit("SET_SHAREHOLDERS", shareholders);
    },
    async updateShareholders ({commit}, shareholder) {
        await commit("UPDATE_SHAREHOLDERS", shareholder);
    },
    async deleteShareholders ({commit}, data) {
        await commit("DELETE_SHAREHOLDERS", data);
    },
    async addShareholders ({commit}) {
        await commit("ADD_SHAREHOLDERS");
    },
    async setFormAddresses ({commit}, formAddresses) {
        await commit("SET_FORM_ADDRESSES", formAddresses);
    },
    async addEmptyFormAddress  ({commit}) {
        await commit("ADD_EMPTY_FORM_ADDRESS");
    },
    async updateFormAddress ({commit}, formAddress) {
        await commit("UPDATE_FORM_ADDRESS", formAddress);
    },
    async deleteFormAddress ({commit}, data) {
        await commit(
            "DELETE_FORM_ADDRESS",
            data
        );
    },
    async setDocumentsPerson ({commit}, documentsPerson) {
        await commit("SET_DOCUMENTS_PERSON", documentsPerson);
    },
    async updateDocumentsPerson ({commit}, documentsPerson) {
        await commit("UPDATE_DOCUMENTS_PERSON", documentsPerson);
    },
    async addDocumentsPerson ({commit}, documentsPerson) {
        await commit("ADD_DOCUMENT_PERSON", documentsPerson);
    },
    async updateIndividual ({commit}, individual) {
        await commit("UPDATE_INDIVIDUAL", individual);
    },
    async addIndividual ({commit}, individual) {
        await commit("ADD_INDIVIDUAL", individual);
    },
    async setFees ({commit}, fees) {
        await commit(
            "SET_FEES",
            fees
        );
    },
    async clearFees ({commit}) {
        await commit(
            "SET_FEES",
            []
        );
    },
    async updateFees ({commit}, fee) {
        await commit(
            "UPDATE_FEES",
            fee
        );
    },
    async editFees ({commit}, fees) {
        await commit(
            "EDIT_FEES",
            fees
        );
    },
    async deleteFees ({commit}, data) {
        await commit(
            "DELETE_FEES",
            data
        );
    },
    async deleteFee ({commit}, data) {
        await commit(
            "DELETE_FEE",
            data
        );
    },
    async addEmptyFees ({commit}) {
        await commit("ADD_EMPTY_FEES");
    },
    async addFees ({commit}, data) {
        await commit("ADD_FEES", data);
    },
    async setTasks ({commit}, tasks) {
        await commit(
            "SET_TASKS",
            tasks
        );
    },
    async updateTasks ({commit}, tasks) {
        await commit(
            "UPDATE_TASKS",
            tasks
        );
    },
    async setCards ({commit}, cards) {
        await commit(
            "SET_CARDS",
            cards
        );
    },
    async setCocInfo ({commit}, cocInfo) {
        await commit(
            "SET_COC_INFO",
            cocInfo
        );
    },
    async setCocNumber ({commit}, cocNumber) {
        await commit(
            "SET_COC_NUMBER",
            cocNumber
        );
    },
    async setIndividuals ({commit}, individuals) {
        await commit(
            "SET_INDIVIDUALS",
            individuals
        );
    },
    async setIndividualsRoles ({commit}, individualRoles) {
        await commit(
            "SET_INDIVIDUALS_ROLES",
            individualRoles
        );
    },
    async setRoles ({commit}, roles) {
        await commit(
            "SET_ROLES",
            roles
        );
    },
    async updateCards ({commit}, card) {
        await commit(
            "UPDATE_CARDS",
            card
        );
    },
    async deleteCards ({commit}, data) {
        await commit(
            "DELETE_CARDS",
            data
        );
    },
    async addCards ({commit}) {
        await commit("ADD_CARDS");
    },
    async updateIndividuals ({commit}, card) {
        await commit(
            "UPDATE_INDIVIDUALS",
            card
        );
    },
    async deleteIndividuals ({commit}, data) {
        await commit(
            "DELETE_INDIVIDUALS",
            data
        );
    },
    async addIndividuals ({commit}) {
        await commit("ADD_INDIVIDUALS");
    },
    async updateBirthDate ({ commit }, individualValues) {
        await commit("UPDATE_BIRTH_DATE", individualValues);
    },
    async addPaymentMechanism ({commit}) {
        await commit("ADD_PAYMENT_MECHANISMS");
    },
    async setPaymentMechanisms ({commit}, paymentMechanisms) {
        await commit(
            "SET_PAYMENT_MECHANISMS",
            paymentMechanisms
        );
    },
    async updatePaymentMechanisms ({commit}, paymentMechanism) {
        await commit(
            "UPDATE_PAYMENT_MECHANISMS",
            paymentMechanism
        );
    },
    async deletePaymentMechanism ({commit}, paymentMechanismData) {
        await commit(
            "DELETE_PAYMENT_MECHANISMS",
            paymentMechanismData
        );
    },
    async dnbLookup ({commit}) {
        const params = this.state.applicationFormValues.dnbSearchParams;
        // eslint-disable-next-line max-len
        const query = gql`query {dunAndBradstreet(countryCode: "${params.country_code}", companyName: "${params.company_name}", dunsNumber: "${params.dunsNumber}", territoryName: "${params.territory}", streetAddress: "${params.address}", telephoneNumber: "${params.phoneNumber}", postalCode: "${params.zipcode}", city: "${params.city}", maxAmountOfResults: ${params.maxResults}, minimumConfidence: ${params.minimumConfidence}, excludeOutOfBusiness: "${params.excludeOutOfBusiness}", excludeUndeliverable: "${params.excludeUndeliverable}", excludeNonHeadquarters: "${params.excludeNonHeadquarters}", excludeUnreachable: "${params.excludeUnreachable}", excludeNonMarketable: "${params.excludeNonMarketable}", companyNumber: "${params.companyNumber}") {responseString matchedObjects{ dnbAddress dnbRelevant dnbDunsNumber dnbCity dnbCountry dnbCompanyName dnbPhone dnbPostalCode dnbTradingName dnbUnreachable dnbUndeliverable dnbOutOfBusiness dnbLocationStatus}}}`;
        await apolloClient.query({query}).then((response) => {
            commit("SET_DNB_RESPONSE_MESSAGE", response.data.dunAndBradstreet.responseString);
            commit("SET_DNB_MATCHES", response.data.dunAndBradstreet.matchedObjects);
        }).catch(() => {
            commit("SET_DNB_MATCHES", []);
        });
    },
    async updateMatchedDnB ({commit}, dnbMatch) {
        await commit("SAVE_DNB_MATCH_TO_FORM", dnbMatch);
    },
    async setAutoDnbLookUpResult ({commit}, dnbLookUp) {
        await commit("SET_AUTO_DNB_LOOKUP_RESULT", dnbLookUp);
    },
    async setDocuments ({commit}, data) {
        await commit(
            "SET_FORM_DOCUMENTS",
            data
        );
    },
    async clearOldValues ({commit}) {
        await commit("CLEAR_OLD_VALUES");
    },
    applyTransition ({state}) {
        const formId = state.values.id;
        const mutation = gql`mutation {apply_transition( form_id: ${formId},
         transitionType: "send_reconfirmation_request"){responseString, error}}`;
        return apolloClient.mutate({mutation});
    },
    async getTemplateValues ({commit, rootState}, data) {
        // eslint-disable-next-line max-len
        const query = gql`query {templateFormValues: application_template (id: ${data.templateId}) {data{category_id template_values}}}`;
        await apolloClient.query({query}).then((response) => {
            const templateValues = JSON.parse(response.data.templateFormValues.data[0].template_values);
            commit(
                "CHANGE_TEMPLATE_VALUES",
                {
                    "template_values": templateValues,
                    rootState,
                    "country": data.country,
                    "category": response.data.templateFormValues.data[0].category_id

                }
            );
            const extractedFees = extractFees(templateValues);
            if (extractedFees) {
                // eslint-disable-next-line no-unused-vars
                for (const [key, value] of Object.entries(extractedFees)) {
                    commit("ADD_FEES", value);
                }
            }
        });
    },
    async solarisCompanyDetails ({state, commit}, companyParams) {
        this.dispatch(
            "loader/setLoading",
            true
        );
        const params = companyParams ? companyParams : state.solarisSearchParams;
        const countryCode = state.values.country;
        commit("SET_SOLARIS_DIALOG", {"dialog": false});
        const query = gql`query {solarisCompanyDetails
        (
            registration_number: "${params.registration_number}"
            registration_issuer: "${params.registration_issuer}"
            country: "${countryCode}"
        ){responseString error data{
             address{postal_code city country line_1 line_2} name legal_form legal_representatives{last_name first_name}
             tax_country registration_number registration_issuer registry_updated_at registration_date}}}`;
        await apolloClient.query({query}).then((response) => {
            commit("SET_COMPANY_DETAILS", response.data.solarisCompanyDetails.data);
            this.dispatch(
                "loader/setLoading",
                false
            );
        }).catch(() => {
            this.dispatch(
                "loader/setLoading",
                false
            );
        });
    },
    setSolarisDialog ({commit}, companyParams) {
        commit("SET_SOLARIS_DIALOG", companyParams);
    },
    saveFleetAmount ({ commit }, data) {
        commit("SAVE_FLEET_AMOUNT", data);
    },
    saveMdmSearch ({ commit }, data) {
        commit("SAVE_MDM_SEARCH", data);
    },
    updateSepaMandateReceived ({commit}, data) {
        if (data === 1) {
            commit("UPDATE_SEPA_MANDATE_RECEIVED", data);
        }
    },
    updateSepaContractSigned ({commit}, data) {
        if (data === 1) {
            commit("UPDATE_SEPA_CONTRACT_SIGNED", data);
        }
    }
};

export default {
    "namespaced": true,
    "state": defaultState,
    getters,
    actions,
    mutations
};
